@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: rgb(1, 72, 113);
  background: linear-gradient(130deg, rgba(1, 72, 113, 1) 0%, rgba(198, 218, 208, 1) 100%);
  font-family: "Inter", sans-serif;
}

span,
.active {
  @apply bg-gradient-to-b from-sky-200 to-blue-300 bg-clip-text font-bold text-transparent;
}

.bg-primary-color {
  @apply bg-gradient-to-b from-[#014871] to-[#c6dad0] bg-clip-text font-bold text-transparent;
}

.primary-color {
  @apply bg-gradient-to-b from-white to-gray-400 bg-clip-text font-bold text-transparent;
}

.sky-color {
  @apply bg-gradient-to-b from-sky-200 to-sky-300 bg-clip-text font-bold text-transparent;
}